import './static/css/common.css';
import { Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Input } from 'reactstrap';

const DeleteModal = ({isDeleteModalOpen, setIsDeleteModalOpen, currentItem, handleDelete}) => {
  //console.log("DeleteModal: " + JSON.stringify(currentItem));
  if (!currentItem) return;

  return (
    <>
    <Modal size="md" isOpen={isDeleteModalOpen} toggle={() => setIsDeleteModalOpen(false)}>
      <Form onSubmit={handleDelete}>
      <Input value={currentItem.id} type="hidden" name="id" />
      <ModalHeader toggle={() => setIsDeleteModalOpen(false)}>Poista sääntö</ModalHeader>
        <ModalBody>
        Haluatko varmasti poistaa säännön '{currentItem.original}'?
        </ModalBody>
        <ModalFooter>
            <Button type="submit" color="primary">Poista</Button>{' '}
            <Button color="secondary" onClick={() => setIsDeleteModalOpen(false)}>Peruuta</Button>
        </ModalFooter>
      </Form>
    </Modal>
    </>
  );
}
export default DeleteModal;
