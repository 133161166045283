import Header from "./Header";
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { checkAuthentication } from "./Auth";

const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    checkAuthentication().then((result) => {
      if (result) {
        //console.log("User authenticated");
        navigate("/dashboard")
      }
    })
  }, [navigate]);
 
  return (
    <>
    <Header/>
    </>
  );
}

export default App;
