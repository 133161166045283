import './static/css/common.css';
import { Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const InfoModal = ({isInfoModalOpen, setIsInfoModalOpen, currentItem}) => {
  //console.log("InfoModal: " + JSON.stringify(currentItem));
  if (!currentItem) return;

  const modified = new Date(currentItem.modified).toLocaleDateString("fi-fi") + " klo " + new Date(currentItem.modified).toLocaleTimeString();

  return (
    <>
    <Modal size="md" isOpen={isInfoModalOpen} toggle={() => setIsInfoModalOpen(false)}>
      <ModalHeader toggle={() => setIsInfoModalOpen(false)}>Tiedot</ModalHeader>
        <ModalBody>
         Käyttäjä: {currentItem.user}<br/>
         Muokattu: {modified}<br/>
        </ModalBody>
        <ModalFooter>
            <Button color="secondary" onClick={() => setIsInfoModalOpen(false)}>Sulje</Button>
        </ModalFooter>
    </Modal>
    </>
  );
}
export default InfoModal;
