import './static/css/common.css';
import { Container, Row, Button } from 'reactstrap';
import { Form, Input } from 'reactstrap';
import { domains } from "./utils/dashboardUtils"


const Rows = ({rows, handleSubmit, originalValue, setOriginalValue, redirectValue, setRedirectValue}) => {

    const domainSelect = { __html: getDomainSelect() };

    return (
    <>
     <Container>          
        <Row>  
        <Form onSubmit={handleSubmit}>
        <table className="table table-striped rule-table">
        <thead>
        <tr>
            <th>Domain</th>
            <th>Alkuperäinen</th>
            <th>Uudelleenohjaus</th>
            <th>Tyyppi</th>
            <th>Alkaa</th>
            <th>Päättyy</th>
        </tr>
        </thead>
        <tbody>
        <tr key="addnew">
            <td><Input type="select" name="domain" id="domain" dangerouslySetInnerHTML={domainSelect}>
                </Input></td>
            <td><Input type="text" value={originalValue} onChange={(e) => setOriginalValue(e.target.value)} name="original" id="original" /></td>
            <td><Input type="text" value={redirectValue} onChange={(e) => setRedirectValue(e.target.value)} name="redirect" id="redirect" /></td>
            <td><Input type="select" name="statusCode" id="statusCode">
                    <option value="301">Pysyvä (301)</option>
                    <option value="302">Väliaikainen (302)</option>
                </Input></td>
            <td><Input type="date" name="startTime" id="startTime"/></td>
            <td><Input type="date" name="endTime" id="endTime"/></td>
            <td><Button type="submit" color="primary">Lisää</Button></td>
        </tr>
        {rows}
        </tbody>
        </table>
        </Form>
        </Row>
        </Container>
    </>
  );
}

const getDomainSelect = () => {
    let html = "";
    for (const domain of domains[process.env.REACT_APP_ENV]) {
       html +=  `<option value="${domain}">${domain}</option>`;
    }
    return html;
}

export default Rows;
