import Header from "./Header";
import Pages from "./Pages";
import Rows from "./Rows";
import UpdateModal from "./UpdateModal";
import InfoModal from "./InfoModal";
import DeleteModal from "./DeleteModal";
import { checkAuthentication } from "./Auth";
import { getItem, originalExists, search, createFormItem, getConfigFile, uploadConfig, checkUrl } from "./utils/dashboardUtils"
import { useEffect, useCallback, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";

import { showSuccessMessage, showErrorMessage, showWarningMessage } from './utils/exceptionUtils';
import { Trash, PencilSquare, Globe, InfoCircle } from 'react-bootstrap-icons';

import './static/css/common.css';

let configJson;
let currentUser;
let pageSize = 200;

const Dashboard = () => {
    const effectRan = useRef(false);
    const isAuthenticated = true;
    const navigate = useNavigate();
    const [rows, updateRows] = useState();
    const [renderFirstPage, updateRenderFirstPage] = useState(false);
    const [stateConfig, setStateConfig] = useState([]);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [originalValue, setOriginalValue] = useState("");    
    const [redirectValue, setRedirectValue] = useState("");    
    const [searchValue, setSearchValue] = useState("");
    const [currentItem, setCurrentItem] = useState(); //getInitItem()
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);

    //console.log(JSON.stringify(currentUser, null, 2));
    //console.log(currentUser?.signInUserSession?.idToken?.payload?.name);
    //console.log("Dashboard searchValue: " + searchValue);

    const changeHandler = useCallback((e) => {
        const newCurrenItem = JSON.parse(JSON.stringify(currentItem));
        newCurrenItem[e.target.name] = e.target.value;
        setCurrentItem(newCurrenItem);
    }, [ currentItem ]);

    const openUpdateModal = useCallback((id) => { 
        setCurrentItem(getItem(id, configJson));      
        setIsUpdateModalOpen(true); 
    }, []);

    const openInfoModal = useCallback((id) => {     
        setCurrentItem(getItem(id, configJson));      
        setIsInfoModalOpen(true);
    }, []);

    const openDeleteModal = useCallback((id) => {     
        setCurrentItem(getItem(id, configJson));      
        setIsDeleteModalOpen(true);
    }, []);

    const openInNewTab = (url) => {
        window.open(checkUrl(url), "_blank", "noreferrer");
    };

    const handleSubmit = (event) => {
        try {
            event.preventDefault();
            const id = event.currentTarget.elements.id?.value;      
            const original = event.currentTarget.elements.original.value;
            const domain = event.currentTarget.elements.domain?.value;
            const redirect = event.currentTarget.elements.redirect.value;
            if ((domain && domain === "https://yle.fi" && !original) || !redirect) showErrorMessage("Virhe", "Alkuperäinen URL ja/tai Uudelleenohjaus eivät voi olla tyhjiä.");
            else if (originalExists(domain, original, id, configJson)) {
                if (id) setIsUpdateModalOpen(false);
                showErrorMessage("Virhe", `Polku (${original}) on jo määritetty.`);
            }
            else if (id) {
                console.log("updateExistingRule searchValue: " + searchValue);
                setIsUpdateModalOpen(false);
                updateExistingRule(event.currentTarget.elements);
                const res = search(searchValue, configJson);
                const pageItems = res.slice((currentIndex*pageSize), ((currentIndex*pageSize)+pageSize));
                setStateConfig(pageItems);
                showSuccessMessage("OK", "Sääntö päivitettiin.");
            }
            else {   
                addNewRule(event.currentTarget.elements);
                setOriginalValue(""); 
                setRedirectValue("");             
                updateRenderFirstPage(!renderFirstPage);
                showSuccessMessage("OK", "Uusi sääntö lisättiin.");
            }                                                      
        } catch (error) {
            console.log('error handling form in', error);
            showErrorMessage("Virhe", "Config-tiedostoa ei voitu päivittää.");
        }
    }

    const handleDelete = (event) => {
        try {
            event.preventDefault();
            removeItem(event.currentTarget.elements);            
            const res = search(searchValue, configJson);
            const pageItems = res.slice((currentIndex*pageSize), ((currentIndex*pageSize)+pageSize));
            setStateConfig(pageItems);
            setIsDeleteModalOpen(false);
            showWarningMessage("OK", "Sääntö poistettin.");
        } catch (error) {
            console.log('error handling delete form in', error);
            showErrorMessage("Virhe", "Sääntöä ei voitu poistaa.");
        }
    };

    const updateItems = useCallback((json) => {
        //console.log("updateItems");
        updateRows(createRows(json, openUpdateModal, openInNewTab, openInfoModal, openDeleteModal));
    // eslint-disable-next-line       
    }, []);

    useEffect(() => {
        //console.log("SearchValue or RenderFirstPage has changed");
        const res = search(searchValue, configJson);
        const pageItems = res.slice(0, pageSize);
        setCurrentPage(0);
        setPageCount(Math.ceil(res.length / pageSize));
        setStateConfig(pageItems);                   
    }, [ searchValue, renderFirstPage ]);

    useEffect(() => {
        //console.log("CurrentIndex has changed");
        const res = search(searchValue, configJson);
        const pageItems = res.slice((currentIndex*pageSize), ((currentIndex*pageSize)+pageSize));
        setCurrentPage(currentIndex);
        setPageCount(Math.ceil(res.length / pageSize));
        setStateConfig(pageItems);
    // eslint-disable-next-line                 
    }, [ currentIndex ]);

    useEffect(() => {
        //console.log("State config changed");
        updateItems(stateConfig);
    // eslint-disable-next-line           
    }, [ stateConfig ]);

    useEffect(() => {
        if (!effectRan.current) {
            checkAuthentication().then((result) => {
            if (result) currentUser = result;
            else {
                console.log("User not authenticated");
                navigate("/");
            }
            });

            getConfigFile().then((result) => {
                configJson = result;
                updateRenderFirstPage(!renderFirstPage);
            });
        }
        return () => effectRan.current = true;
        // eslint-disable-next-line
    }, [ ]);

    return (
        <>
        <Header isAuthenticated={isAuthenticated} searchValue={searchValue} setSearchValue={setSearchValue} setCurrentPage={setCurrentPage}/>   
        <Pages pageCount={pageCount} currentPage={currentPage} setCurrentPage={setCurrentPage} setCurrentIndex={setCurrentIndex}/>    
        <Rows rows={rows} handleSubmit={handleSubmit} originalValue={originalValue} setOriginalValue={setOriginalValue} redirectValue={redirectValue} setRedirectValue={setRedirectValue} />
        <UpdateModal isUpdateModalOpen={isUpdateModalOpen} setIsUpdateModalOpen={setIsUpdateModalOpen} currentItem={currentItem} changeHandler={changeHandler} handleSubmit={handleSubmit} />
        <InfoModal isInfoModalOpen={isInfoModalOpen} setIsInfoModalOpen={setIsInfoModalOpen} currentItem={currentItem} />
        <DeleteModal isDeleteModalOpen={isDeleteModalOpen} setIsDeleteModalOpen={setIsDeleteModalOpen} currentItem={currentItem} handleDelete={handleDelete} />        
        </>
    );
};

const createRows = (configJson, openUpdateModal, openInNewTab, openInfoModal, openDeleteModal) => {
    
    if (!configJson) return;
    return (     
        <>
        { configJson.map((item, index) => (
            <tr key={item.id}>             
                <td colSpan={2}>{parseDomain(item)}</td>
                <td>{item.redirect}</td>
                <td>{item.statusCode === "301" ? "Pysyvä (301)" : "Väliaikainen (302)"}</td>
                <td>{item.startTime}</td>
                <td>{item.endTime}</td>
                <td>
                    <Globe className="icon" onClick={() => openInNewTab(item.original)}/>
                    &nbsp;&nbsp;&nbsp;
                    <PencilSquare  className="icon" onClick={() => openUpdateModal(item.id)}/>
                    &nbsp;&nbsp;&nbsp;
                    <InfoCircle  className="icon" onClick={() => openInfoModal(item.id)}/>
                    &nbsp;&nbsp;&nbsp;
                    <Trash  className="icon" onClick={() => openDeleteModal(item.id)}/>
                </td>
            </tr>
        ))}
        </>
    );
};

const parseDomain = (item) => {
    return item.original.startsWith("http") ? item.original : "https://yle.fi" + item.original;
}

const addNewRule = async (elements) => {

    const newItem = createFormItem(elements);
    newItem.modified = new Date().toISOString();
    newItem.user = currentUser?.signInUserSession?.idToken?.payload?.email;
    configJson.unshift(newItem);
    uploadConfig(configJson)
    return true;
};

const updateExistingRule = async (elements) => {

    const formItem = createFormItem(elements);
    const item = getItem(elements.id.value, configJson);
    const user = currentUser?.signInUserSession?.idToken?.payload?.email;

    item.original = formItem.original;
    item.redirect = formItem.redirect
    item.statusCode = formItem.statusCode;
    item.startTime = formItem.startTime;
    item.endTime = formItem.endTime;
    item.modified = new Date().toISOString();
    item.user = user;
    //console.log(JSON.stringify(item));

    uploadConfig(configJson);
};

const removeItem = (elements) => {
    
    const id = elements.id.value;
    for (const [index, item] of configJson.entries()) { 
        if (item.id === id) {
            configJson.splice(index,1);
            break;
        }
    }
    uploadConfig(configJson);
};

export default Dashboard;
