import React from "react";
import SnackBar from '..//SnackBar';
import ReactDOM from 'react-dom/client';

const container = ReactDOM.createRoot(document.getElementById('snackbar-fixed-container'));

const triggerSnackbar = (title, message, messageType) => {
    const validMessageTypes = ['error', 'info', 'warning', 'success'];
    if (!validMessageTypes.includes(messageType)) {
        throw Error("Invalid snackbar message type");
    }    
    container.render(
        <SnackBar messageType={messageType} timer={4000} title={title} message={message} container={container} />
    );
}

export const showErrorMessage = (title, message) => {
    triggerSnackbar(title, message, 'error');
}

export const showInfoMessage = (title, message) => {
    triggerSnackbar(title, message, 'info');
}

export const showSuccessMessage = (title, message) => {
    triggerSnackbar(title, message, 'success');
}

export const showWarningMessage = (title, message) => {
    triggerSnackbar(title, message, 'warning');
}
