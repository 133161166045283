import './static/css/common.css';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const Pages = ({pageCount, currentPage, setCurrentPage, setCurrentIndex}) => {

  //console.log("pageCount: " + pageCount + ": " + new Date());
  
  const handleClick = (e, index) => {
    e.preventDefault();    
    setCurrentIndex(index);
  }

  return (
    <>
    <Pagination aria-label="Page navigation example">
      
    {[...Array(pageCount)].map((page, i) => 
        <PaginationItem active={i === currentPage} key={i}>
          <PaginationLink onClick={e => handleClick(e, i)} href="#">
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      )}
    </Pagination>
    </>
  );
}
export default Pages;
