import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Amplify } from 'aws-amplify';

import "bootstrap/dist/css/bootstrap.min.css";

import './static/css/index.css';
import App from './App';
import Dashboard from "./Dashboard";
import reportWebVitals from './reportWebVitals';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_USER_POOL_ID, 
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,      
      redirectSignIn: process.env.REACT_APP_DEFAULT_URL,
      redirectSignOut: process.env.REACT_APP_DEFAULT_URL,
      responseType: 'code'
    }
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_RULES_BUCKET, 
      region: process.env.REACT_APP_REGION,
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route index element={<App />} />
        <Route path="dashboard" element={<Dashboard />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



//console.log(process.env);
