import './static/css/common.css';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Row, Col, Button } from 'reactstrap';
import { Form, Input } from 'reactstrap';

const UpdateModal = ({isUpdateModalOpen, setIsUpdateModalOpen, currentItem, changeHandler, handleSubmit}) => {
  //console.log("UpdateModal: " + JSON.stringify(currentItem));

  if (!currentItem) return;

  return (
    <>
     <Modal size="xl" isOpen={isUpdateModalOpen} toggle={() => setIsUpdateModalOpen(false)}>
        <Form onSubmit={handleSubmit}>
        <Input value={currentItem.id} type="hidden" name="id" />
        <ModalHeader toggle={() => setIsUpdateModalOpen(false)}>Muokkaa sääntöä</ModalHeader>
        <ModalBody>
        <Row className="margin-bottom-10">
            <Col>Alkuperäinen</Col>
            <Col>Uudelleenohjaus</Col>
            <Col>Tyyppi</Col>
            <Col>Alkaa</Col>
            <Col>Päättyy</Col>
        </Row>     
        <Row>
            <Col><Input value={currentItem.original} onChange={e => changeHandler(e)} type="text" name="original" id="original" /></Col>
            <Col><Input value={currentItem.redirect} onChange={e => changeHandler(e)}  type="text" name="redirect" id="redirect" /></Col>
            <Col><Input value={currentItem.statusCode} onChange={e => changeHandler(e)}  type="select" name="statusCode" id="statusCode">
                <option value="301">Pysyvä (301)</option>
                <option value="302">Väliaikainen (302)</option>
            </Input></Col>
            <Col><Input value={currentItem.startTime} onChange={e => changeHandler(e)}  type="date" id="startTime" name="startTime"/></Col>
            <Col><Input value={currentItem.endTime} onChange={e => changeHandler(e)}  type="date" id="endTime"  name="endTime"/></Col>
        </Row>          
        
        </ModalBody>
        <ModalFooter>
            <Button type="submit" color="primary">Tallenna</Button>{' '}
            <Button color="secondary" onClick={() => setIsUpdateModalOpen(false)}>Peruuta</Button>
        </ModalFooter>
        </Form>
    </Modal>
    </>
  );
}


export default UpdateModal;
