import { Auth } from 'aws-amplify';

export const checkAuthentication = async () => {
  try {    
      const user = await Auth.currentAuthenticatedUser();
      const email = user?.signInUserSession?.idToken?.payload?.email;
      //console.log("email: " + email);
      if (await checkPermission(email)) return user;
      else return null;
  } catch (error) {
      console.log('Error checking authentication: ', error);
  }
  return null;
}

export const signOut = async () => {
  try {
      //console.log("Signing out");
      await Auth.signOut();
  } catch (error) {
      console.log('Error signing out: ', error);
  }
}

export const getUser = async () => {
  try {
      return await Auth.currentAuthenticatedUser();
  } catch (error) {
      console.log('User not found: ', error);
  }
  return null;
}

export const checkPermission = async (email) => {
  if (!email) return false;
  const whiteList = await getWhitelist();
  if (whiteList && whiteList.includes(await hash(email))) return true;
  return false;
}

export const hash = async (string) => {
  const utf8 = new TextEncoder().encode(string);
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
}

export const getWhitelist = async () => {
  const server = process.env.REACT_APP_ENV === 'local' ? 'https://yle-redirector-test.aws.yle.fi/' : process.env.REACT_APP_DEFAULT_URL;
  const res = await fetch(`${server}static/allowed_hash.txt`);
  if (res.ok) return await res.text();
  return "";
}